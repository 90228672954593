import { AuthOptions, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client'; // eslint-disable-line no-restricted-imports
import { setContext } from '@apollo/client/link/context';

import { Env } from '@/lib/env';
import { BrowserAuth } from '@/lib/auth/browser';
import { globalLogger } from '@/lib/logger';

const logger = globalLogger.child({ module: 'subscriptions-store' });

const region = 'eu-west-1';
const url = Env.gql.endpoint;
const auth: AuthOptions = {
  type: 'AWS_LAMBDA',
  async token() {
    const accessToken = await BrowserAuth.Session.getBrowserAccessToken();
    if (!accessToken) {
      /*
       * We cannot throw an error here because this runs on the server through SSR before reaching
       * the browser and the access token will be `null` in that case, so throwing an error would
       * break the entire app.
       */
      return '';
    }

    return `Bearer ${accessToken}`;
  },
};

/**
 * Create an ApolloClient instance. When running on SSR, it will create a dummy client that does not
 * connect to AppSync. This is because subscriptions do not need to be established on the server,
 * and we want to avoid making unnecessary connections to AppSync.
 */
function makeApolloClient() {
  logger.debug('Creating Apollo client');

  const httpLink = new HttpLink({ uri: url });
  const link = ApolloLink.from([
    setContext(async (_operation, { headers }) => {
      return {
        headers: {
          ...headers,
          'x-iofinnet-agent': `dashboard/${Env.application.gitCommit} ${
            typeof window === 'undefined' ? '' : window.navigator.userAgent
          }`.trim(),
        },
      };
    }),

    createAuthLink({ url, region, auth }),
    createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
  ]);

  // Return a client that does not connect to app sync when running in a server environment
  if (typeof window === 'undefined') {
    return new ApolloClient({ cache: new InMemoryCache() });
  }

  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });
}

let client: ReturnType<typeof makeApolloClient> | null = null;
let restartLock = false;
let subscribers = new Set<() => void>();

function emitChange() {
  for (const subscriber of subscribers) {
    subscriber();
  }
}

/**
 * Store for the Apollo client used for GraphQL subscriptions.
 * This store is used to manage the Apollo client instance and to allow components to subscribe to
 * changes in the client.
 *
 * The client can be restarted. This is useful when there are connection errors that currently we cannot recover from.
 */
const store = {
  getClient() {
    if (!client) {
      client = makeApolloClient();
    }
    return client;
  },

  restartClient() {
    if (restartLock) return;

    restartLock = true;

    logger.warn('Restarting Apollo client');
    client?.stop();
    emitChange();

    /**
     * We need to release the lock after some time to prevent the client from being restarted
     * multiple times in quick succession. This can happen if multiple components try to restart
     * the client at the same time.
     */
    setTimeout(() => {
      client = makeApolloClient();
      logger.warn('Releasing restart lock');
      restartLock = false;
      emitChange();
    }, 10_000);
  },

  isSubscriptionsClientConnectionError(error: any) {
    return error instanceof Error && error.message?.includes('Connection closed');
  },

  subscribe(callback: () => void) {
    subscribers.add(callback);
    return () => subscribers.delete(callback);
  },

  getServerSnapshot() {
    return null;
  },
};

export const GqlClientStore = store;
export type SubscriptionsClient = ReturnType<typeof store.getClient>;
