import { GraphQLClient } from 'graphql-request'; // eslint-disable-line no-restricted-imports
import type { RequestConfig } from 'graphql-request/build/esm/types';

import { Env } from '@/lib/env';
import { globalLogger } from '@/lib/logger';
import { BrowserAuth } from '@/lib/auth/browser';

import { deFragment } from '../de-fragmenter';

const logger = globalLogger.child({ scope: 'gql-client' });

function hackRequestBodyToRemoveFragments(
  ...params: Parameters<NonNullable<RequestConfig['requestMiddleware']>>
): ReturnType<NonNullable<RequestConfig['requestMiddleware']>> {
  const originalData = params[0];
  const body = JSON.parse(originalData.body as string);
  const query = deFragment(body.query);

  if (query) {
    return { ...originalData, body: JSON.stringify({ ...body, query }) };
  }

  return originalData;
}

export const gqlClient = new GraphQLClient(Env.gql.endpoint, {
  async requestMiddleware(originalData) {
    const headers = {
      ...originalData.headers,
      'x-iofinnet-agent': `dashboard/${Env.application.gitCommit} (${navigator.userAgent})`,
    };

    // Add the access token to the headers if it is not already there (to allow use to use next cache for some requests)
    if (!('Authorization' in headers)) {
      const accessToken = await BrowserAuth.Session.getBrowserAccessToken();
      (headers as Record<string, string>).Authorization = `Bearer ${accessToken}`;
    }

    const data = hackRequestBodyToRemoveFragments({
      ...originalData,
      headers,
    });

    logger.trace({ data }, 'will make GraphQL request');
    return data;
  },
  responseMiddleware(response) {
    if (response instanceof Error && response.message.includes('NEXT_REDIRECT')) {
      return;
    }

    if (response instanceof Error && (response.name === 'AbortError' || response.message === 'Aborted')) {
      logger.trace({ err: response }, 'call to backend API was aborted');
    } else if (response instanceof Error) {
      logger.error({ err: response }, 'got error from GraphQL API');
    } else if (response.errors?.some((it) => it.extensions.code === 'UNAUTHENTICATED')) {
      logger.error({ response }, 'GraphQL response contains errors');
    }
  },
  async fetch(input, init) {
    const response = await fetch(input as string, init);
    if (response.status === 401) {
      logger.error({ response }, 'backend API returned a 401 error');
      BrowserAuth.Session.signOut();
    }

    return response;
  },
});
